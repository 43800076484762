import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Col, Row, Image } from "react-bootstrap"

import cablesImage from "../images/cables.jpg"

const NetworkingPage = () => (
  <Layout>
    <SEO title="Networking" />
    <Container style={{ marginTop: "2rem" }}>
      <Row>
        <Col>
          <h1>Premier Networking Solutions</h1>
          <h5>Networking to help your business succeed.</h5>
          <p>
            Network security software and appliances for small and medium
            business (SMB), education and nonprofit organizations. Our lives are
            continually being shaped by the internet for good and bad. We
            provide connectivity solutions that work together seamlessly to
            ensure protection, monitoring and control across the entire digital
            attack surface.
          </p>
          <p>
            This security solution is known as Unified Treat Management (UTM)
            which is an Enterprise Class system that is now available for any
            business including (SMB). Services include:
          </p>
          <p>Services include:</p>
          <ul>
            <li>
              Web Filter to control what web sites can be viewed, accessed or
              blocked
            </li>
            <li>
              Virus Blocker to block malware data while in transit before it is
              consumed by the end point devices in a digital attack
            </li>
            <li>
              Application Control to control and monitor installed apps on
              devices
            </li>
            <li>
              Intrusion protection to mitigate or remove digital attack surface
            </li>
            <li>VPN to support secure remote connections</li>
            <li>Reporting and management</li>
          </ul>

          <Image style={{ margin: "2rem 0" }} rounded src={cablesImage}></Image>

          <div style={{ margin: "4rem 0" }}></div>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default NetworkingPage
